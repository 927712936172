import axios from 'axios'

export default {
  fetchClients () {
    return axios.get('activation-invites/alfa-customers').then(response => {
      return response.data
    })
  },

  getAlfaHostname () {
    return axios.get('activation-invites/alfa-hostname').then(response => {
      return response.data
    })
  },
}
