var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "alfaClientslist", tag: "section" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12" } },
            [
              _vm.error
                ? _c(
                    "base-material-alert",
                    { attrs: { dark: "", color: "error", dismissible: "" } },
                    [_vm._v("\n        " + _vm._s(_vm.error) + "\n      ")]
                  )
                : _vm._e(),
              _c(
                "base-material-card",
                {
                  attrs: { color: "success", icon: "mdi-badge-account" },
                  scopedSlots: _vm._u([
                    {
                      key: "after-heading",
                      fn: function() {
                        return [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { right: "", "max-width": "50%" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "div",
                                        _vm._g(
                                          {
                                            staticClass:
                                              "card-title font-weight-light"
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                Клиенты в AlfaCRM\n                "
                                          ),
                                          _c(
                                            "v-icon",
                                            { staticClass: "help-pointer" },
                                            [
                                              _vm._v(
                                                "\n                  mdi-help-circle-outline\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "Список всех клиентов в AlfaCRM. Данный список необходим для отправки приглашений клиентам центров в ЛК."
                                )
                              ])
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pt-0" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: { cols: "12", md: "4" }
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pa-0 ma-0" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "append-icon": "mdi-magnify",
                                          label:
                                            "Поиск по имени, фамилии, номеру телефона, email",
                                          "single-line": "",
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: _vm.search,
                                          callback: function($$v) {
                                            _vm.search = $$v
                                          },
                                          expression: "search"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0 ma-0",
                                      attrs: { cols: "auto" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "help-pointer pt-5 pb-5 pl-2 pr-5",
                                          attrs: { title: "Очистить поиск" },
                                          on: {
                                            click: function($event) {
                                              _vm.search = ""
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    mdi-close\n                  "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    attrs: {
                      loading: _vm.loading,
                      "loading-text": "Загрузка списка клиентов...",
                      headers: _vm.headers,
                      items: _vm.items,
                      search: _vm.search,
                      "items-per-page": _vm.itemsPerPage,
                      page: _vm.page
                    },
                    on: {
                      "update:itemsPerPage": function($event) {
                        _vm.itemsPerPage = $event
                      },
                      "update:items-per-page": function($event) {
                        _vm.itemsPerPage = $event
                      },
                      "update:page": function($event) {
                        _vm.page = $event
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.name",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("span", [_vm._v(_vm._s(item.name))]),
                            _c(
                              "v-btn",
                              {
                                staticClass: "mr-0 pr-0",
                                attrs: {
                                  icon: "",
                                  href: _vm.singleClientLink(item.id),
                                  target: "_blank",
                                  title:
                                    "Открыть карточку клиента в новой вкладке"
                                }
                              },
                              [_c("v-icon", [_vm._v("mdi-open-in-new")])],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "item.phone",
                        fn: function(ref) {
                          var item = ref.item
                          return _vm._l(item.phone, function(phone) {
                            return _c(
                              "span",
                              { key: phone },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(phone) +
                                    "\n              "
                                ),
                                phone
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-0 pr-0",
                                        attrs: {
                                          icon: "",
                                          title:
                                            "Отправить клиенту приглашение и код для доступа в ЛК"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.showConfirmInviteDialog(
                                              item
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-card-account-mail")
                                        ])
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          })
                        }
                      },
                      {
                        key: "item.email",
                        fn: function(ref) {
                          var item = ref.item
                          return _vm._l(item.email, function(email) {
                            return _c(
                              "span",
                              { key: email },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(email) +
                                    "\n              "
                                ),
                                email
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-0 pr-0",
                                        attrs: {
                                          icon: "",
                                          title:
                                            "Отправить клиенту приглашение и код для доступа в ЛК"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.showConfirmInviteDialog(
                                              item
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-card-account-mail")
                                        ])
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          })
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "450" },
          model: {
            value: _vm.confirmInviteDialog,
            callback: function($$v) {
              _vm.confirmInviteDialog = $$v
            },
            expression: "confirmInviteDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(
                  "\n        Отправить приглашение в ЛК на email клиента?\n      "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  "\n        Клиент получит письмо с кодом активации и СМС на телефон для привязки ученика в своем личном кабинете на сайте\n        https://www.poliglotiki.ru/client/\n      "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmInviteDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Отмена\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.sendInvite()
                        }
                      }
                    },
                    [_vm._v("\n          Отправить приглашение\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }