<template>
  <v-container
    id="alfaClientslist"
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <base-material-alert
          v-if="error"
          dark
          color="error"
          dismissible
        >
          {{ error }}
        </base-material-alert>

        <base-material-card
          color="success"
          icon="mdi-badge-account"
        >
          <template v-slot:after-heading>
            <v-tooltip
              right
              max-width="50%"
            >
              <template v-slot:activator="{ on }">
                <div
                  class="card-title font-weight-light"
                  v-on="on"
                >
                  Клиенты в AlfaCRM
                  <v-icon
                    class="help-pointer"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                </div>
              </template>
              <span>Список всех клиентов в AlfaCRM. Данный список необходим для отправки приглашений клиентам центров в ЛК.</span>
            </v-tooltip>
          </template>

          <v-card-text
            class="pt-0"
          >
            <v-row>
              <v-col
                cols="12"
                md="4"
                class="pt-0 pb-0"
              >
                <v-row>
                  <v-col
                    class="pa-0 ma-0"
                  >
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Поиск по имени, фамилии, номеру телефона, email"
                      single-line
                      hide-details
                    />
                  </v-col>
                  <v-col
                    class="pa-0 ma-0"
                    cols="auto"
                  >
                    <v-icon
                      title="Очистить поиск"
                      class="help-pointer pt-5 pb-5 pl-2 pr-5"
                      @click="search = ''"
                    >
                      mdi-close
                    </v-icon>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            :loading="loading"
            loading-text="Загрузка списка клиентов..."
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
          >
            <template v-slot:item.name="{ item }">
              <span>{{ item.name }}</span>
              <v-btn
                icon
                :href="singleClientLink(item.id)"
                target="_blank"
                title="Открыть карточку клиента в новой вкладке"
                class="mr-0 pr-0"
              >
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.phone="{ item }">
              <span
                v-for="phone in item.phone"
                :key="phone"
              >
                {{ phone }}
                <v-btn
                  v-if="phone"
                  icon
                  title="Отправить клиенту приглашение и код для доступа в ЛК"
                  class="mr-0 pr-0"
                  @click="showConfirmInviteDialog(item)"
                >
                  <v-icon>mdi-card-account-mail</v-icon>
                </v-btn>
              </span>
            </template>
            <template v-slot:item.email="{ item }">
              <span
                v-for="email in item.email"
                :key="email"
              >
                {{ email }}
                <v-btn
                  v-if="email"
                  icon
                  title="Отправить клиенту приглашение и код для доступа в ЛК"
                  class="mr-0 pr-0"
                  @click="showConfirmInviteDialog(item)"
                >
                  <v-icon>mdi-card-account-mail</v-icon>
                </v-btn>
              </span>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="confirmInviteDialog"
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">
          Отправить приглашение в ЛК на email клиента?
        </v-card-title>

        <v-card-text>
          Клиент получит письмо с кодом активации и СМС на телефон для привязки ученика в своем личном кабинете на сайте
          https://www.poliglotiki.ru/client/
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="darken-1"
            text
            @click="confirmInviteDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="error darken-1"
            text
            @click="sendInvite()"
          >
            Отправить приглашение
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
  .v-data-table tr {
    cursor: pointer;
  }

  .archived {
    opacity: 0.5;
    text-decoration: line-through;
  }

  .group-chip {
    height: auto !important;
    margin-bottom: 1px !important;
  }

  .help-pointer {
    cursor: pointer;
  }
</style>

<script>
  import moment from 'moment'
  import CRMStorage from '../services/CRMStorage'
  import alfaApi from '../services/AlfaApi'
  import inviteApi from '../services/InviteApi'

  export default {
    filters: {
      formatBirthday: function (date) {
        return moment(date).format('DD.MM.YYYY')
      },
      formatDateFromUnixTime: function (unixtime) {
        return moment.unix(unixtime).format('DD.MM.YYYY HH:mm')
      },
    },
    data () {
      return {
        search: CRMStorage.getItem('ACL_search', ''),

        page: 1,
        itemsPerPage: CRMStorage.getItem('ACL_itemsPerPage', 10),
        headers: [
          {
            sortable: true,
            text: 'Id',
            value: 'id',
            visible: true,
          },
          {
            sortable: true,
            text: 'Имя ребенка',
            value: 'name',
            visible: true,
          },
          {
            sortable: true,
            text: 'Имя родителя',
            value: 'legal_name',
            visible: true,
          },
          {
            sortable: true,
            text: 'Телефон',
            value: 'phone',
            visible: true,
          },
          {
            sortable: true,
            text: 'Email',
            value: 'email',
            visible: true,
          },
          {
            text: 'Дата рождения',
            value: 'dob',
            visible: true,
          },
        ],
        items: [],
        alfaHostname: null,
        loading: true,
        error: null,
        confirmInviteDialog: false,
        inviteEmail: null,
        inviteId: null,
        fieldsErrors: [],
        fieldError (fieldName) {
          let errors = []
          if (this.fieldsErrors) {
            this.fieldsErrors.filter(field => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },
      }
    },
    watch: {
      search: function (val) {
        this.page = 1
        CRMStorage.setItem('ACL_search', val)
      },
      itemsPerPage: function (val) {
        CRMStorage.setItem('ACL_itemsPerPage', val)
      },
      page: function (val) {
        CRMStorage.setItem('ACL_page', val)
      },
      items: {
        handler () {
          this.page = +CRMStorage.getItem('ACL_page', 1)
          setTimeout(function () {
            window.scrollTo(0, +CRMStorage.getItem('ACL_scrollY', 0))
          }, 100)
        },
        deep: true,
      },
    },

    mounted () {
      document.title = 'Клиенты в Alfa CRM | ' + process.env.VUE_APP_NAME
      // if (!this.itemsPerPage) this.itemsPerPage = 10
      // if (!this.page) this.page = 1

      alfaApi.fetchClients()
        .then(response => {
          this.items = this.items.concat(response)
          this.loading = false
        })
        .catch(error => {
          this.handleError(error)
        })

      alfaApi.getAlfaHostname()
        .then(response => {
          this.alfaHostname = response
        })
        .catch(error => {
          this.handleError(error)
        })
    },
    methods: {
      singleClientLink (clientId) {
        return 'https://' + this.alfaHostname + '/company/1/customer/view?id=' + clientId
      },

      showConfirmInviteDialog (client) {
        this.inviteClient = client
        this.confirmInviteDialog = true
      },

      sendInvite () {
        this.confirmInviteDialog = false
        this.loading = true
        inviteApi
          .sendAlfaInvite(this.inviteClient.email, this.inviteClient.phone, this.inviteClient.id)
          .then(response => {
            this.loading = false
          })
          .catch(error => {
            let data = error.response.data
            if (error.response.status === 422) {
              this.fieldsErrors = data
            } else {
              this.error = this.pretty(data)
            }
            this.loading = false
          })
      },

      complete (index) {
        this.list[index] = !this.list[index]
      },

      formatDate (date) {
        if (!date) return null
        return moment(date).format('DD.MM.YYYY')
      },

      compareArrays (first, second) {
        if (!first || !second) return false
        return first.every((e) => second.includes(e)) && second.every((e) => first.includes(e))
      },
      handleError (errorResponse) {
        if (errorResponse && errorResponse.response) {
          let data = errorResponse.response.data
          if (errorResponse.response.status === 422) {
            this.fieldsErrors = data
          } else if (errorResponse.response.status === 401) {
            this.error = 'Неверный логин или пароль! Войдите в систему заново!'
          } else if (data.message) {
            this.error = data.message
          } else if (data) {
            this.error = JSON.stringify(data, null, 2)
          } else {
            this.error = 'Неизвестная ошибка системы. Попробуйте повторить запрос, а затем обратитесь на care@poliglotiki.ru.'
          }
        } else {
          this.error = 'Неизвестная ошибка системы. Попробуйте повторить запрос, а затем обратитесь на care@poliglotiki.ru.'
        }
        this.loading = false
      },

      ageToStr (age) {
        let txt
        let count = age % 100
        if (count >= 5 && count <= 20) {
          txt = 'лет'
        } else {
          count = count % 10
          if (count === 1) {
            txt = 'год'
          } else if (count >= 2 && count <= 4) {
            txt = 'года'
          } else {
            txt = 'лет'
          }
        }
        return age + ' ' + txt
      },

    },
  }
</script>
